import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';

// assets
import { Camera, Trash } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';
const AddPlan = ({ customer, onCancel, open, setData, updateData, setCustomerDeleteId }) => {
    const theme = useTheme();
    const { createPlan, updatePlan } = useAuth();
    const isCreating = !customer;
    const [loading, setLoading] = useState(false);

    const [initialValuesState, setInitialValuesState] = useState({
        name: customer?.name || '',
        assistance_total: customer?.assistance_total || '',
        code: customer?.code || '',
    })

    useEffect(() => {
        if (customer) {
            setInitialValuesState({
                name: customer?.name || '',
                assistance_total: customer?.assistance_total || '',
                code: customer?.code || '',
            })
        } else {
            formik.resetForm()
            setInitialValuesState({
                name: '',
                assistance_total: '',
                code: '',
            })
        }
    }, [customer])

    const CustomerSchema = Yup.object().shape({
        name: Yup.string().max(255).required('Ingrese el nombre del plan.'),
        assistance_total: Yup.string().max(255).required('Ingrese la cantidad de asistencias.'),
        code: Yup.string().max(255)
    });

    const handleClose = () => {
        setCustomerDeleteId(null)
        formik.resetForm();
        onCancel();
    };

    const formik = useFormik({
        initialValues: initialValuesState,
        enableReinitialize: true,
        validationSchema: CustomerSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true)
                setLoading(true)
                if (customer) {
                    let formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("assistance_total", values.assistance_total);
                    formData.append("code", values.code);

                    // for (var pair of formData.entries()) {
                    //     console.log(`${pair[0]}: ${pair[1]}`);
                    // }

                    updatePlan(customer?.uuid, formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Plan editado exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                updateData();
                                onCancel();
                                resetForm();
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                        })
                } else {
                    let formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("assistance_total", values.assistance_total);
                    formData.append("code", values.code);

                    // for (var pair of formData.entries()) {
                    //     console.log(`${pair[0]}: ${pair[1]}`);
                    // }

                    createPlan(formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Plan creado exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                updateData()
                                onCancel();
                                resetForm();
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                        })
                }
                resetForm();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle>{customer ? "Editar Plan" : "Crear Plan"}</DialogTitle>
                        <Divider />
                        <DialogContent >
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} md={7}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name">Nombre</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="name"
                                                    placeholder="Ingrese el nombre del plan"
                                                    {...getFieldProps('name')}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="assistance_total">Cantidad de Asistencias</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="assistance_total"
                                                    type={"number"}
                                                    placeholder="Ingrese la cantidad de asistencias del plan"
                                                    {...getFieldProps('assistance_total')}
                                                    error={Boolean(touched.assistance_total && errors.assistance_total)}
                                                    helperText={touched.assistance_total && errors.assistance_total}
                                                />
                                            </Stack>
                                        </Grid>
                                        {customer && (
                                            <Grid item xs={12}>
                                                <Stack spacing={1.25}>
                                                    <InputLabel htmlFor="code">Código del plan</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        id="code"
                                                        placeholder="Ingrese el código del plan"
                                                        {...getFieldProps('code')}
                                                        error={Boolean(touched.code && errors.code)}
                                                        helperText={touched.code && errors.code}
                                                    />
                                                </Stack>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="end" alignItems="center">
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleClose}>
                                            Cancelar
                                        </Button>
                                        {!loading ? (
                                            <LoadingButton type="submit" variant="contained" className={`bg-orange-500 hover:bg-orange-400`} loading={loading}>
                                                {customer ? "Editar Plan" : "Crear Plan"}
                                            </LoadingButton>
                                        ) : (
                                            <LoadingButton type="submit" variant="contained" loading={true}>
                                                Aplicando cambios.
                                            </LoadingButton>
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
        </>
    );
};

export default AddPlan;