import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

// assets
import useAuth from 'hooks/useAuth';
const AddHospital = ({ customer, onCancel, updateData }) => {
    const { createHospital, updateHospital } = useAuth();
    const isCreating = !customer;
    const [selectedStartTime, setStartTime] = useState('');
    const [selectedEndTime, setEndTime] = useState('');

    const [startTimes, setStartTimes] = useState([
        {
            id: "06:00:00",
            name: "06:00:00",
        },
        {
            id: "07:00:00",
            name: "07:00:00",
        },
        {
            id: "08:00:00",
            name: "08:00:00",
        },
        {
            id: "09:00:00",
            name: "09:00:00",
        }
    ]);
    const [endTimes, setEndTimes] = useState([
        {
            id: "16:00:00",
            name: "16:00:00",
        },
        {
            id: "17:00:00",
            name: "17:00:00",
        },
        {
            id: "18:00:00",
            name: "18:00:00",
        },
        {
            id: "19:00:00",
            name: "19:00:00",
        }
    ]);

    const [initialValuesState, setInitialValuesState] = useState({
        name: customer?.name || '',
        phone: customer?.phone || '',
        address: customer?.address || '',
        time_start: customer?.time_start || '',
        time_end: customer?.time_end || '',
    })

    const handleStartTimeChange = (event) => {
        const time = event.target.value;
        console.log(time);
        setStartTime(time);
        formik.setFieldValue('time_start', time.name);
    };

    const handleEndTimeChange = (event) => {
        const time = event.target.value;
        setEndTime(time);
        formik.setFieldValue('time_end', time.name);
    };

    useEffect(() => {
        if (customer) {
            const start = {
                id: customer?.time_start || '',
                name: customer?.time_start || '',
            }
            const end = {
                id: customer?.time_end || '',
                name: customer?.time_end || '',
            }
            setStartTime(start);
            setEndTime(end);
            setInitialValuesState({
                name: customer?.name || '',
                phone: customer?.phone || '',
                address: customer?.address || '',
                time_start: customer?.time_start || '',
                time_end: customer?.time_end || '',
            })
        } else {
            formik.resetForm()
            setInitialValuesState({
                name: '',
                phone: '',
                address: '',
                time_start: '',
                time_end: '',
            })
        }
    }, [customer])

    const CustomerSchema = Yup.object().shape({
        name: Yup.string().max(255).required('Ingrese el nombre del cliente'),
        phone: Yup.string().max(255).required('Ingrese el teléfono del cliente'),
        address: Yup.string().max(255).required('Ingrese la dirección del cliente'),
    });

    const handleClose = () => {
        //setCustomerDeleteId(null)
        formik.resetForm();
        onCancel();
    };

    const formik = useFormik({
        initialValues: initialValuesState,
        enableReinitialize: true,
        validationSchema: CustomerSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                if (customer) {
                    let formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("phone", values.phone);
                    formData.append("address", values.address);
                    formData.append("time_start", values.time_start);
                    formData.append("time_end", values.time_end);
                    // for (var pair of formData.entries()) {
                    //     console.log(`${pair[0]}: ${pair[1]}`);
                    // }

                    updateHospital(customer?.uuid, formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Clínica actualizada exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                updateData()
                                resetForm();
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                } else {
                    let formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("phone", values.phone);
                    formData.append("address", values.address);
                    formData.append("time_start", values.time_start);
                    formData.append("time_end", values.time_end);
                    // for (var pair of formData.entries()) {
                    //     console.log(`${pair[0]}: ${pair[1]}`);
                    // }

                    createHospital(formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Clínica actualizada exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                updateData()
                                resetForm();
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                }
                resetForm();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle>{customer ? 'Editar Clínica' : 'Nueva Clínica'}</DialogTitle>
                        <Divider />
                        <DialogContent >
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} md={7}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name">Nombre</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="name"
                                                    placeholder="Ingresa el nombre del usuario"
                                                    {...getFieldProps('name')}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name">Teléfono</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="phone"
                                                    placeholder="Ingresa el teléfono del usuario"
                                                    {...getFieldProps('phone')}
                                                    error={Boolean(touched.phone && errors.phone)}
                                                    helperText={touched.phone && errors.phone}
                                                />
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name">Dirección</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="address"
                                                    placeholder="Ingresa la dirección del usuario"
                                                    {...getFieldProps('address')}
                                                    error={Boolean(touched.address && errors.address)}
                                                    helperText={touched.address && errors.address}
                                                />
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="customer-time-start">Hora de inicio</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id="time-start"
                                                        {...getFieldProps('time_start')}
                                                        onChange={handleStartTimeChange}
                                                        displayEmpty
                                                        input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                                                        renderValue={(selected) => {
                                                            if (!selected) {
                                                                return <Typography variant="body1">Selecciona una opción</Typography>;
                                                            }
                                                            return <Typography variant="subtitle2">{selectedStartTime.name}</Typography>;
                                                        }}
                                                    >
                                                        {startTimes.map((time) => (
                                                            <MenuItem key={time.id} value={time}>
                                                                {time.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {touched.time_start && errors.time_start && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                                                        {errors.time_start}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="customer-time-end">Hora de cierre</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id="time-end"
                                                        {...getFieldProps('time_end')}
                                                        onChange={handleEndTimeChange}
                                                        displayEmpty
                                                        input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                                                        renderValue={(selected) => {
                                                            if (!selected) {
                                                                return <Typography variant="body1">Selecciona una opción</Typography>;
                                                            }
                                                            return <Typography variant="subtitle2">{selectedEndTime.name}</Typography>;
                                                        }}
                                                    >
                                                        {endTimes.map((time) => (
                                                            <MenuItem key={time.id} value={time}>
                                                                {time.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {touched.time_end && errors.time_end && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                                                        {errors.time_end}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="end" alignItems="center">
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleClose}>
                                            Cancelar
                                        </Button>
                                        <Button type="submit" variant="contained" className='bg-orange-500 hover:bg-orange-400' disabled={isSubmitting}>
                                            {customer ? 'Editar' : 'Agregar'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
        </>
    );
};

AddHospital.propTypes = {
    customer: PropTypes.any,
    onCancel: PropTypes.func
};

export default AddHospital;