import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';

// assets
import { Camera, Trash } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';

const AddSpecie = ({ customer, onCancel, open, setData, updateData, setCustomerDeleteId }) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const { createSpecie, updateSpecie } = useAuth();
    const isCreating = !customer;

    const [initialValuesState, setInitialValuesState] = useState({
        name: customer?.name || '',
    })

    useEffect(() => {
        if (customer) {
            setInitialValuesState({
                name: customer?.name || '',
            })
        } else {
            formik.resetForm()
            setInitialValuesState({
                name: '',
            })
        }
    }, [customer])

    const CustomerSchema = Yup.object().shape({
        name: Yup.string().max(255).required('Ingrese el nombre de la especie'),
    });

    const handleClose = () => {
        setCustomerDeleteId(null)
        formik.resetForm();
        onCancel();
    };

    const formik = useFormik({
        initialValues: initialValuesState,
        enableReinitialize: true,
        validationSchema: CustomerSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                setLoading(true);
                if (customer) {
                    let formData = new FormData();
                    formData.append("name", values.name);
                    // for (var pair of formData.entries()) {
                    //     console.log(`${pair[0]}: ${pair[1]}`);
                    // }

                    updateSpecie(customer?.uuid, formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Especie editada exitosamente',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                updateData()
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setLoading(false);
                            setSubmitting(false);
                            onCancel();
                        })
                } else {
                    let formData = new FormData();
                    formData.append("name", values.name);
                    // for (var pair of formData.entries()) {
                    //     console.log(`${pair[0]}: ${pair[1]}`);
                    // }

                    createSpecie(formData)
                        .then((response) => {
                            console.log(response.data)
                            if (response.data.success) {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: 'Especie creada exitosamente.',
                                        variant: 'alert',
                                        alert: {
                                            color: 'success'
                                        },
                                        close: true
                                    })
                                );
                                updateData()
                            } else {
                                dispatch(
                                    openSnackbar({
                                        open: true,
                                        message: response.data.message,
                                        variant: 'alert',
                                        alert: {
                                            color: 'error'
                                        },
                                        close: true
                                    })
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            )
                        })
                        .finally(() => {
                            setLoading(false);
                            setSubmitting(false);
                            onCancel();
                        })
                }
                resetForm();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle>{customer ? "Editar" : "Agregar"} Especie</DialogTitle>
                        <Divider />
                        <DialogContent >
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={12} md={7}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name">Nombre</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    id="name"
                                                    placeholder="Ingresa el nombre de la especie"
                                                    {...getFieldProps('name')}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="end" alignItems="center">
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleClose}>
                                            Cancelar
                                        </Button>
                                        <LoadingButton loading={loading} type="submit" variant="contained" className='bg-orange-500 hover:bg-orange-400' disabled={isSubmitting}>
                                            {customer ? "Editar" : "Agregar"}
                                        </LoadingButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
        </>
    );
};

export default AddSpecie;