import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    Slide,
    InputLabel,
} from '@mui/material';
import { PopupTransition } from 'components/@extended/Transitions';
import { ArrowLeft, Edit2, Trash, Add, Save2, Cancel } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import AlertSpecieDelete from './AlertSpecieDelete';
import AlertBreedDelete from './AlertBreedDelete';

const BreedInfo = ({ breeds, handleClosePlan, openPlan, user, onUpdate, onDelete }) => {
    const { updateBreed, createBreed, deleteBreed } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentView, setCurrentView] = useState('list'); 
    const [selectedBreed, setSelectedBreed] = useState(null);
    const [breedName, setBreedName] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleEdit = (breed) => {
        setSelectedBreed(breed);
        setBreedName(breed.name);
        setCurrentView('edit');
    };

    const handleDelete = () => {
        setOpen(false);
        // Lógica para eliminar la raza
        console.log('Delete breed:', selectedBreed);

        deleteBreed(selectedBreed?.uuid)
            .then((response) => {
                // console.log(response.data)

                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Raza eliminada correctamente.',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                onDelete(selectedBreed)
            }).catch((error) => {
                console.error(error)
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error al eliminar raza.',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }).finally(() => {
                setSelectedBreed(null);
                //setLoadingData(false)
            })
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedBreed(null);
    };

    const handleAdd = () => {
        setBreedName('');
        setCurrentView('add');
    };

    const handleSave = () => {
        setLoading(true);

        if (currentView === 'edit') {
            let formData = new FormData();
            formData.append("name", breedName);

            updateBreed(selectedBreed?.uuid, formData)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.success) {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Raza editada exitosamente',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: true
                            })
                        );
                        onUpdate(response.data.data)
                    } else {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: response.data.message,
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        )
                    }
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    )
                })
                .finally(() => {
                    setLoading(false);
                    handleCancel();
                })
        } else if (currentView === 'add') {
            let formData = new FormData();
            formData.append("name", breedName);
            formData.append("specie_id", user.id);

            createBreed(formData)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.success) {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Raza creada exitosamente.',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: true
                            })
                        );
                        onUpdate(response.data.data)
                    } else {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: response.data.message,
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        )
                    }
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    )
                })
                .finally(() => {
                    setLoading(false);
                    handleCancel();
                })
        }
    };

    const handleCancel = () => {
        setCurrentView('list');
    };

    return (
        <>
            <Dialog
                maxWidth="md"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={handleClosePlan}
                open={openPlan}
                sx={{ '& .MuiDialog-paper': { borderRadius: 2, p: 2 }, transition: 'transform 225ms' }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h4">Razas de: {user?.name || ''}</Typography>
                        {currentView === 'list' && (
                            <Button variant="contained" startIcon={<Add />} size="small" className='bg-orange-500 hover:bg-orange-400 py-2 px-3'
                                onClick={handleAdd}
                            >
                                Agregar raza
                            </Button>
                        )}
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ maxHeight: "50%" }}>
                    <Divider sx={{ mb: 1 }} />
                    <Box sx={{ display: currentView !== 'list' ? 'block' : 'none' }}>
                        <Stack spacing={2}>
                            <InputLabel htmlFor="name" sx={{ mt: 2 }}>Nombre de la Raza</InputLabel>
                            <TextField
                                name='name'
                                fullWidth
                                value={breedName}
                                onChange={(e) => setBreedName(e.target.value)}
                            />
                        </Stack>
                    </Box>

                    {currentView === 'list' && (
                        breeds && breeds?.length > 0 ? (
                            <TableContainer sx={{ borderRadius: 2 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre de la Raza</TableCell>
                                            <TableCell align="right">Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {breeds.map((breed) => (
                                            <TableRow key={breed.id}>
                                                <TableCell>{breed.name}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton onClick={() => handleEdit(breed)} color={"primary"}>
                                                        <Edit2 />
                                                    </IconButton>
                                                    <IconButton onClick={() => {
                                                        setSelectedBreed(breed)
                                                        setOpen(true)
                                                    }} color={"error"}>
                                                        <Trash />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                                <Typography variant="h5">No hay razas registradas</Typography>
                            </Box>
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" justifyContent="end" spacing={1}>
                        {currentView !== 'list' && (
                            <>
                                <Button onClick={handleCancel}>
                                    Cancelar
                                </Button>
                                <LoadingButton loading={loading} variant="contained" size="small" className='bg-orange-500 hover:bg-orange-400 py-2 px-3' onClick={handleSave}>
                                    Guardar
                                </LoadingButton>
                            </>
                        )}
                        {currentView === 'list' && (
                            <Button onClick={handleClosePlan} variant='outlined' color="primary" sx={{ mr: 4, px: 4 }}>
                                Cerrar
                            </Button>
                        )}
                    </Stack>
                </DialogActions>
            </Dialog>
            <AlertBreedDelete open={open} handleClose={handleClose} handleDelete={handleDelete} breed={selectedBreed} />
        </>
    );
};

export default BreedInfo;