import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material'
import { PopupTransition } from 'components/@extended/Transitions'
import useAuth from 'hooks/useAuth'
import { ArrowLeft, ArrowRight, Filter } from 'iconsax-react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { formatDateString } from 'utils/aihubUtils'
import PetHistoryAppointmentCard from './PetHistoryAppointmentCard'

const PlanInfo = ({ planData, handleClosePlan, openPlan, user }) => {
    const { getBookingList, getServicesList } = useAuth();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([])
    const [services, setServices] = useState([])
    const [loadingServices, setLoadingServices] = useState(false);
    const [filterSelected, setFilterSelected] = useState(null);
    const [selectedService, setSelectedService] = useState(null);

    const fetchBookingList = async () => {
        if (!user) return;

        setLoading(true);
        try {
            const response = await getBookingList(user?.uuid, 10, currentPage, filterSelected);
            if (response.data.success) {
                setData(response.data.data.data)
                setTotalPages(response.data.data.last_page);
                if (filterSelected && services) {
                    setSelectedService(services?.find(service => service.id === filterSelected))
                } else {
                    setSelectedService(null);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const fetchServicesList = async () => {
        if (!user) return;

        setLoadingServices(true);
        try {
            const response = await getServicesList(user?.uuid);
            if (response.data.success) {
                setServices(response.data.data)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingServices(false);
        }
    }

    useEffect(() => {
        fetchBookingList()
    }, [user, currentPage, filterSelected])

    useEffect(() => {
        fetchServicesList()
    }, [user])


    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    return (
        <Dialog
            maxWidth="md"
            TransitionComponent={PopupTransition}
            keepMounted
            fullWidth
            onClose={handleClosePlan}
            open={openPlan}
            sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Detalles del Plan</DialogTitle>
            <DialogContent>
                <Divider sx={{ mb: 1 }} />
                <Stack spacing={1.5} alignItems="start" justifyContent={"start"}>
                    <Typography variant="body1"><strong>{planData?.assistance_plan?.name || ""}</strong></Typography>
                    {/* <Typography variant="body1">Total asistencias: <strong>{planData?.assistance_plan?.assistance_total || 0}</strong></Typography> */}
                    <Typography variant="body1">Asistencias solicitadas: <strong>{planData?.count_assistance_used || 0}</strong></Typography>
                    <Typography variant="body1">Registrado: <strong>{formatDateString(planData?.start_date_relationship)}</strong></Typography>
                    <Typography variant="body1">Periodo vigencia: <strong>{formatDateString(planData?.start_date_period)} - {formatDateString(planData?.end_date_period)}</strong></Typography>
                </Stack>
                <Divider sx={{ my: 1.5 }} />
                <Stack spacing={1.25}>
                    <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
                        <Typography htmlFor="pet-dateOfBirth" sx={{ pb: { xs: 2, md: 0 } }}><strong>Historial de consultas:
                            {selectedService && (
                                <>
                                    {loadingServices || loading ? (
                                        <span className='ml-1 font-bold text-sm'>
                                            <Skeleton variant="text" width={200} />
                                        </span>
                                    ) : (
                                        <div className='font-bold text-sm text-orange-500'>
                                            {data?.length} solicitadas de {selectedService?.detail_service_plan_assistance[0]?.booking_limit || "Sin límite"}
                                        </div>
                                    )}
                                </>
                            )}
                        </strong></Typography>
                        <FormControl>

                            <Select
                                labelId="filter-select-label"
                                id="filter-select"
                                value={filterSelected}
                                onChange={(event) => setFilterSelected(event.target.value)}
                                displayEmpty
                                sx={{ width: "250px" }}
                                renderValue={(value) => {
                                    if (!value) {
                                        return <em>Filtrar por servicio</em>;
                                    }
                                    const service = services.find(service => service.id === value);
                                    return service?.name || "Filtrar por servicio";
                                }}
                            >
                                <MenuItem value={null}>
                                    <p>Ver Todas</p>
                                </MenuItem>
                                {services.map((service) => (
                                    <MenuItem key={service.id} value={service.id}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </div>
                    <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {loading ? (
                            <Box sx={{ display: "flex", my: 5, justifyContent: "center", alignItems: "center" }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {data && data?.length <= 0 ? (
                                    <Typography variant="h6" my={2} textAlign={"center"}>No se encontró historial de asistencias.</Typography>
                                ) : (
                                    data.map((appointment) => (
                                        <PetHistoryAppointmentCard appointment={appointment} key={appointment.id} />
                                    ))
                                )}
                            </>

                        )}
                    </Box>
                </Stack>
                {currentPage !== totalPages && (
                    <Stack direction="row" justifyContent="space-between" mt={1}>
                        <Button onClick={handlePreviousPage} disabled={currentPage === 1} startIcon={<ArrowLeft />}>
                            Anterior
                        </Button>
                        <Button onClick={handleNextPage} disabled={currentPage === totalPages} endIcon={<ArrowRight />}>
                            Siguiente
                        </Button>
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClosePlan} variant="outlined">Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PlanInfo