import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    LinearProgress,
    linearProgressClasses,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import { PatternFormat } from 'react-number-format';
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
    CSVExport,
    HeaderSort,
    IndeterminateCheckbox,
    SortingSelect,
    TablePagination,
    TableRowSelection
} from 'components/third-party/ReactTable';

import AddCustomer from 'sections/apps/customer/AddCustomer';
import CustomerView from 'sections/apps/customer/CustomerView';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';

import makeData from 'data/react-table';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';

// assets
import { Add, DocumentText, Edit, Eye, Pet, Trash } from 'iconsax-react';
import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/system';
import useDataStore from 'store/useDataStore';
import useAuthStore from 'store/useAuthStore';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { formatedDate, formatID } from 'utils/appUtils';
import AlertClientStatusChange from './AlertClientStatusChange';
import AddClients from './AddClients';
import EditClient from './EditClient';
import { useNavigate } from 'react-router';
import { formatDateString } from 'utils/aihubUtils';
import PlanInfo from './PlanInfo';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useAuthStore();
    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'id', desc: false };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setHiddenColumns,
        allColumns,
        visibleColumns,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
        preGlobalFilteredRows,
        setGlobalFilter,
        setSortBy,
        selectedFlatRows
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: [], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    // useEffect(() => {
    //     if (matchDownSM) {
    //         setHiddenColumns(['age', 'contact', 'visits', 'status', 'avatar', 'age']);
    //     } else {
    //         setHiddenColumns(['avatar', 'age']);
    //     }
    //     // eslint-disable-next-line
    // }, [matchDownSM]);

    return (
        <>
            {/*<TableRowSelection selected={Object.keys(selectedRowIds).length} />*/}
            <Stack spacing={3}>
                <Stack
                    direction={matchDownSM ? 'column' : 'row'}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 3, pb: 0 }}
                >
                    <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
                        <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
                        {/* {user?.rol[0] === "Admin" || user?.rol[0] === "Super Admin" && (
                            <> */}
                        <Button variant="contained" startIcon={<Add />} onClick={handleAdd} size="small" className='bg-orange-500 hover:bg-orange-400 p-2'>
                            Agregar clientes
                        </Button>
                        {/* <CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={'users-list.csv'} />
                            </>
                        )} */}
                    </Stack>
                </Stack>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                        <HeaderSort column={column} sort />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();

                            return (
                                <Fragment key={i}>
                                    <TableRow
                                        {...row.getRowProps()}
                                        // onClick={() => {
                                        //     row.toggleRowSelected();
                                        // }}
                                        sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                                    >
                                        {row.cells.map((cell) => (
                                            <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                                </Fragment>
                            );
                        })}
                        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
}

// ReactTable.propTypes = {
//     columns: PropTypes.array,
//     data: PropTypes.array,
//     getHeaderProps: PropTypes.func,
//     handleAdd: PropTypes.func,
//     renderRowSubComponent: PropTypes.any
// };


const ClientListPage = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    // const data = useMemo(() => makeData(5), []);
    const [loadingData, setLoadingData] = useState(true)
    // const [data, setData] = useState([])
    const [open, setOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerDeleteId, setCustomerDeleteId] = useState('');
    const [customerDeleteName, setCustomerDeleteName] = useState("")
    const [selectedUserPlan, setSelectedUserPlan] = useState(null)
    // const { users, addUser, setUsers } = useDataStore();
    const [users, setUsers] = useState([])
    const [add, setAdd] = useState(false);
    const { getUserList, deleteUser, updateUser, getPetClientList } = useAuth();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false)
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);
    const navigate = useNavigate();
    const [openPlan, setOpenPlan] = useState(false);
    const [planData, setPlanData] = useState(null)

    const handleOpenPlan = (plan, user) => {
        setPlanData(plan);
        setSelectedUserPlan(user)
        setOpenPlan(true);
    };

    const handleClosePlan = () => {
        setPlanData(null);
        setSelectedUserPlan(null)
        setOpenPlan(false);
    };

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/' },
            { label: 'Lista de Clientes', path: '/client/list' },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs]);

    const handleAdd = () => {
        setAdd(!add);
        if (customer && !add) {
            setCustomer(null);
        }
    };

    const handleClose = () => {
        setOpen(!open);
    };

    const getUuidById = (id) => {
        // console.log(id)
        const foundObject = users.find(item => item.id === id);
        return foundObject ? foundObject.uuid : "";
    };

    const getUserById = (id) => {
        const foundObject = users.find(item => item.id === id);
        return foundObject;
    };

    const handleStatusDialog = () => {
        setStatusDialogOpen(!statusDialogOpen);
    }

    const handleStatusDialogClose = (customer) => {
        setStatusDialogOpen(!statusDialogOpen); // Cerrar el diálogo
        try {
            let formData = new FormData();
            //if (selectedImage) formData.append("logo", selectedImage);
            formData.append("status", customer.status === "inactive" ? "active" : "inactive")

            // for (var pair of formData.entries()) {
            //     console.log(`${pair[0]}: ${pair[1]}`);
            // }

            updateUser(customer?.uuid, formData)
                .then((response) => {
                    // console.log(response.data)
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Estado actualizado exitosamente',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    fetchUsers()
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    )
                })
                .finally(() => {
                    //setSubmitting(false);
                    //onCancel();
                })
            resetForm();
        } catch (error) {
            console.error(error);
        }
    }

    const handleDelete = () => {
        setOpen(!open);
        // console.log("Deleting: ", customerDeleteName)
        // console.log("ID: ", getUuidById(customerDeleteId))
        deleteUser(getUuidById(customerDeleteId))
            .then((response) => {
                // console.log(response.data)
                fetchUsers();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Usuario eliminado correctamente.',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            }).catch((error) => {
                console.error(error)
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error al eliminar usuario.',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }).finally(() => {
                //setLoadingData(false)
            })
    };

    const fetchUsers = () => {
        setLoadingData(true)
        getPetClientList()
            .then((response) => {
                setUsers(response.data.data)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                setLoadingData(false)
            })
    }

    useEffect(() => {
        if (users.length <= 0) {
            fetchUsers()
        }
    }, [])

    const columns = useMemo(() => [
        {
            Header: '#',
            accessor: 'id',
            className: 'cell-center',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Typography variant="body1">{formatID(values.id)}</Typography>
                );
            }
        },
        {
            Header: 'NOMBRE',
            accessor: 'name',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        {values.avatar ? (
                            <Avatar alt={values.name} size="sm" src={values.avatar} />
                        ) : (
                            <Avatar alt={values.name} size="sm">{values.name.charAt(0).toUpperCase()}</Avatar>
                        )}
                        <Stack spacing={0}>
                            <Typography variant="subtitle1">{values.name}</Typography>
                        </Stack>
                    </Stack>
                );
            }
        },
        {
            Header: 'DNI',
            accessor: 'dni'
        },
        {
            Header: 'EMAIL',
            accessor: 'email'
        },
        {
            Header: 'INFORMACIÓN',
            accessor: 'phone',
            Cell: ({ row }) => {
                const { original } = row;
                return (
                    <Stack spacing={1.5} alignItems="start" justifyContent={"start"}>
                        <Typography variant="body1">Teléfono: {original.phone || ""}</Typography>
                        <Typography variant="body1">Dir: {original.address}</Typography>
                    </Stack>
                );
            }
        },
        {
            Header: 'PLAN',
            accessor: 'client_assistance_plan',
            Cell: ({ row }) => {
                const { original } = row;
                const planData = original.client_assistance_plan
                return (
                    <>
                        {planData ? (
                            <Stack spacing={1.5} alignItems="center" justifyContent={"center"}>
                                <Typography variant="body1"><strong>{planData?.assistance_plan?.name || ""}</strong></Typography>
                                <Button variant="outlined" onClick={() => handleOpenPlan(planData, original)} sx={{ textTransform: "none", fontWeight: "600" }} >Ver más inf.</Button>
                            </Stack>
                        ) : (
                            <Stack spacing={1.5} alignItems="center" justifyContent={"center"}>
                                <Typography variant="body1"><strong>Sin plan</strong></Typography>
                            </Stack>
                        )}
                    </>
                );
            }
        },
        {
            Header: 'ESTADO',
            accessor: 'status',
            Cell: ({ value }) => {
                switch (value.toLowerCase()) {
                    case 'active':
                        return <Chip className={"bg-[#06BF7B21] text-green-500"} label="Activo" size="small" variant="filled" />;
                    case 'pending':
                        return <Chip className={"bg-[#FEBF2021] text-[#A47A10]"} label="Pendiente" size="small" variant="filled" />;
                    case 'inactive':
                        return <Chip className={"bg-[#FEBF2021] text-[#A47A10]"} label="Supendido" size="small" variant="filled" />;
                    default:
                        return <Chip label="Indefinido" size="small" variant="filled" />;
                }
            }
        },
        // {
        //     Header: 'SUSPENDER',
        //     //accessor: row => <Switch defaultChecked={row.suspended} className='z-50' />
        //     Cell: ({ row }) => {
        //         const { values } = row;
        //         return (
        //             <Switch defaultChecked={values?.status?.toLowerCase() === "inactive"} checked={values?.status?.toLowerCase() === "inactive"} onChange={(e) => {
        //                 e.stopPropagation();
        //                 handleStatusDialogClose();
        //                 setCustomerDeleteId(values.id);
        //                 setCustomerDeleteName(values.name);
        //             }} className='z-50' />
        //         )
        //     }
        // },
        {
            Header: 'ACCIONES',
            className: 'cell-center',
            disableSortBy: true,
            Cell: ({ row }) => {
                const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="start" spacing={0}>
                        {/* <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="View"
                        >
                            <IconButton
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    row.toggleRowExpanded();
                                }}
                            >
                                {collapseIcon}
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Editar"
                        >
                            <IconButton
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    //setCustomer(row.values);
                                    setCustomerDeleteId(row.values.id)
                                    //setCustomer(getUserById(row.values.id))
                                    handleAdd();
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Pet"
                        >
                            <IconButton
                                color="warning"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const { original } = row;
                                    navigate(`/pets/list/${original.uuid}`);
                                    handleAdd();
                                }}
                            >
                                <Pet />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Eliminar"
                        >
                            <IconButton
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClose();
                                    setCustomerDeleteId(row.values.id);
                                    setCustomerDeleteName(row.values.name);
                                }}
                            >
                                <Trash />
                            </IconButton>
                        </Tooltip> */}
                    </Stack>
                );
            }
        }
        // Aquí puedes ajustar las demás columnas como 'ACCIONES' según necesites...
    ], [theme]);


    const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={users[Number(row.id)]} />, [users]);


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    return (
        <MainCard content={false}>
            <ScrollX>
                <ReactTable columns={columns} data={users} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />
                {loadingData && <BorderLinearProgress variant="indeterminate" value={50} />}
            </ScrollX>
            <AlertCustomerDelete title={customerDeleteName} open={open} handleClose={handleClose} handleDelete={handleDelete} />
            <AlertClientStatusChange title={customerDeleteId} open={statusDialogOpen} handleClose={handleStatusDialog} updateData={() => handleStatusDialogClose(getUserById(customerDeleteId))} name={customerDeleteName} />
            <Dialog
                maxWidth="sm"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={handleAdd}
                open={add}
                sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                aria-describedby="alert-dialog-slide-description"
            >
                {customerDeleteId ? (
                    <EditClient customer={getUserById(customerDeleteId)} onCancel={handleAdd} open={add} updateData={fetchUsers} setCustomerDeleteId={setCustomerDeleteId} />
                ) : (
                    <AddClients customer={customer} onCancel={handleAdd} open={add} setData={setUsers} />
                )}
            </Dialog>
            <PlanInfo handleClosePlan={handleClosePlan} openPlan={openPlan} planData={planData} user={selectedUserPlan} />
        </MainCard>
    );
}

export default ClientListPage;