import PropTypes from 'prop-types';

// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project-imports
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { Trash } from 'iconsax-react';
import useAuth from 'hooks/useAuth';

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function AlertPlanDelete({ data, open, handleClose, refresh }) {
  const { deletePlan } = useAuth();

  const handleDelete = () => {
    deletePlan(data.uuid)
      .then((response) => {
        console.log(response)
        refresh();
        handleClose();
      }).catch((error) => {
        console.error(error)
      }).finally(() => { });
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color="error" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            <Trash variant="Bold" />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              ¿Seguro que desea eliminar el plan?
            </Typography>
            <Typography align="center">
              <Typography variant="subtitle1" component="span">
                {' '}
                &quot;{data?.name || 'No definido'}&quot;{' '}
              </Typography>
            </Typography>
            {data?.users_count && data?.users_count > 0 && (
              <>
                <Typography variant="subtitle1" component="span" textAlign={"center"}>
                  Antes de eliminar un plan, asegúrate de actualizar los datos del plan en la base de datos para todos los usuarios activos ({data?.users_count}) asociados con dicho plan.
                </Typography>
              </>
            )}
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={() => handleClose(false)} color="secondary" variant="outlined">
              Cancelar
            </Button>
            <Button fullWidth color="error" className='bg-red-500' variant="contained" onClick={handleDelete} autoFocus>
              Eliminar
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

AlertPlanDelete.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
